<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
    type?:
      | 'large'
      | 'title-1'
      | 'title-2'
      | 'title-3'
      | 'headline-1'
      | 'headline-2'
      | 'body-bold'
      | 'body-1'
      | 'body-2'
      | 'callout'
      | 'subheadline-1'
      | 'subheadline-2'
      | 'footnote-1'
      | 'footnote-2'
      | 'caption-1'
      | 'caption-2'
      | 'navigation';
  }>(),
  {
    element: 'p',
    type: 'body-1',
  }
);

const classes = computed(() => {
  // append your typography css here
  return [`font-${props.type}`, 'custom-typography'];
});
</script>

<template>
  <component :is="element" data-testid="typography" :class="classes">
    <slot></slot>
  </component>
</template>

<style></style>
